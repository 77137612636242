<template>
  <div
    style="display: flex; height: 100%; width: 100%; background-color: white"
  >
    <v-row style="flex-wrap: nowrap">
      <v-col
        style="
          padding: 0px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <v-col
          style="
            max-width: 370px;
            max-height: 340px;
            height: 100%;
            margin-right: 30px;
            margin-left: 30px;
            display: flex;
            flex-direction: column;
          "
        >
          <div
            style="
              display: flex;
              height: 64px;
              flex-direction: column;
              justify-content: space-between;
            "
          >
            <h3>{{ $t("CompleteRegistration") }}</h3>
            <div class="content" style="margin-top: 12px">{{ userEmail }}</div>
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;
              gap: 12px;
              margin-top: 32px;
            "
          >
            <v-text-field
              name="password-input"
              type="password"
              prepend-inner-icon="mdi-lock"
              outlined
              v-model="password"
              :label="$t('Password')"
              hide-details
            ></v-text-field>
            <v-text-field
              @keydown.enter="submit"
              name="password-input"
              type="password"
              prepend-inner-icon="mdi-lock"
              outlined
              v-model="passwordConfirmation"
              :label="$t('ConfirmPassword')"
              hide-details
            ></v-text-field>
          </div>
          <div style="margin-bottom: auto; margin-top: 15px; color: red">
            {{ this.passwordConfirmationMessage }}
          </div>
          <div style="display: inline-flex">
            <div class="add-button" @click="submit">
              <img
                style="margin-right: 9px"
                alt=""
                src="../assets/images/checkmarkCircled.svg"
              />
              <p>
                {{ $t("SubmitUser") }}
              </p>
            </div>
          </div>
        </v-col>
      </v-col>
      <v-col
        v-bind:class="{
          'image-col': !isUusMaa,
          'image-col-uusmaa': isUusMaa,
          'image-col-kaanon': isKaanon,
          'image-col-domus': isDomus,
        }"
      >
        <img
          class="company-logo"
          v-if="isUusMaa || isKaanon || isDomus"
          :src="companyLogoUrl"
          alt="Company Logo"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Register",
  data() {
    return {
      userEmail: "",
      password: "",
      passwordConfirmation: "",
      passwordConfirmationMessage: "",
    };
  },
  created() {
    this.getEmail();
  },
  computed: {
    isUusMaa() {
      return window.location.hostname === "uusmaa.runproperty.com";
    },
    isKaanon() {
      return window.location.hostname === "kaanon.runproperty.com";
    },
    isDomus() {
      return window.location.hostname === "domus.runproperty.com";
    },
    companyLogoUrl() {
      if (this.isUusMaa) {
        return require("@/assets/images/uusmaa_logo.svg")
      }
      if (this.isKaanon) {
        return require("@/assets/images/kaanon-logo.svg");
      }
      if (this.isDomus) {
        return require("@/assets/images/domus-logo.svg");
      }
      return null;
    },
  },
  watch: {
    passwordConfirmation() {
      if (
        this.password.length === this.passwordConfirmation.length &&
        this.password !== this.passwordConfirmation
      ) {
        this.passwordConfirmationMessage = this.$t(
          "FormErrors.PasswordsDontMatch"
        );
      } else {
        this.passwordConfirmationMessage = "";
      }
    },
  },
  methods: {
    async getEmail() {
      await axios
        .get(`/api/new-user-email/${this.$route.params.id}`)
        .then((res) => {
          this.userEmail = res.data[0].email;
        })
        .catch((err) => {
          console.log(err);
          //this.$router.push({name: "Login"});
        });
    },
    async submit() {
      if (this.password.length < 8) {
        this.passwordConfirmationMessage = this.$t(
          "FormErrors.Password8Characters"
        );
        return;
      }

      if (this.password === this.passwordConfirmation) {
        const request = {
          activationId: this.$route.params.id,
          password: this.password,
        };
        await axios
          .post("/api/finalize-registration", request)
          .then((res) => {
            console.log(res);
            this.$router.push({
              name: "Login",
              params: { email: this.userEmail, password: this.password },
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style scoped>
.add-button {
  cursor: pointer !important;
  padding-left: 16px;
  padding-right: 16px;
}
.add-button p {
  color: white;
}
.image-col {
  background-color: #f4f5f7;
  height: 100vh;
  background-image: url("../assets/images/loginImage.svg");
  background-position: center bottom;
}
.image-col-uusmaa {
  background-color: #f4f5f7;
  height: 100vh;
  background-image: url(https://uusmaa.ee/wp-content/uploads/2019/08/AdobeStock_271403301-1-1024x674.jpeg);
  background-position: center;
  background-size: cover;
  justify-content: center;
  align-items: flex-end;
  display: flex;
}
.image-col-kaanon {
  background-color: #f4f5f7;
  height: 100vh;
  background-image: url("../assets/images/kaanon-background.png");
  background-position: center;
  background-size: cover;
  justify-content: center;
  align-items: flex-end;
  display: flex;
}
.image-col-domus {
  background-color: #f4f5f7;
  height: 100vh;
  background-image: url("../assets/images/domus-background.jpg");
  background-position: center;
  background-size: cover;
  justify-content: center;
  align-items: flex-end;
  display: flex;
}
.company-logo {
  position: absolute;
  top: 100px;
  max-height: 94px;
}
</style>
