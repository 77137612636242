var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "display": "flex",
      "height": "100%",
      "width": "100%",
      "background-color": "white"
    }
  }, [_c('v-row', {
    staticStyle: {
      "flex-wrap": "nowrap"
    }
  }, [_c('v-col', {
    staticStyle: {
      "padding": "0px",
      "width": "100%",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('v-col', {
    staticStyle: {
      "max-width": "370px",
      "max-height": "340px",
      "height": "100%",
      "margin-right": "30px",
      "margin-left": "30px",
      "display": "flex",
      "flex-direction": "column"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "height": "64px",
      "flex-direction": "column",
      "justify-content": "space-between"
    }
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t("CompleteRegistration")))]), _c('div', {
    staticClass: "content",
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_vm._v(_vm._s(_vm.userEmail))])]), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "12px",
      "margin-top": "32px"
    }
  }, [_c('v-text-field', {
    attrs: {
      "name": "password-input",
      "type": "password",
      "prepend-inner-icon": "mdi-lock",
      "outlined": "",
      "label": _vm.$t('Password'),
      "hide-details": ""
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _c('v-text-field', {
    attrs: {
      "name": "password-input",
      "type": "password",
      "prepend-inner-icon": "mdi-lock",
      "outlined": "",
      "label": _vm.$t('ConfirmPassword'),
      "hide-details": ""
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.passwordConfirmation,
      callback: function ($$v) {
        _vm.passwordConfirmation = $$v;
      },
      expression: "passwordConfirmation"
    }
  })], 1), _c('div', {
    staticStyle: {
      "margin-bottom": "auto",
      "margin-top": "15px",
      "color": "red"
    }
  }, [_vm._v(" " + _vm._s(this.passwordConfirmationMessage) + " ")]), _c('div', {
    staticStyle: {
      "display": "inline-flex"
    }
  }, [_c('div', {
    staticClass: "add-button",
    on: {
      "click": _vm.submit
    }
  }, [_c('img', {
    staticStyle: {
      "margin-right": "9px"
    },
    attrs: {
      "alt": "",
      "src": require("../assets/images/checkmarkCircled.svg")
    }
  }), _c('p', [_vm._v(" " + _vm._s(_vm.$t("SubmitUser")) + " ")])])])])], 1), _c('v-col', {
    class: {
      'image-col': !_vm.isUusMaa,
      'image-col-uusmaa': _vm.isUusMaa,
      'image-col-kaanon': _vm.isKaanon,
      'image-col-domus': _vm.isDomus
    }
  }, [_vm.isUusMaa || _vm.isKaanon || _vm.isDomus ? _c('img', {
    staticClass: "company-logo",
    attrs: {
      "src": _vm.companyLogoUrl,
      "alt": "Company Logo"
    }
  }) : _vm._e()])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }